import {
  format,
  parseISO,
  isBefore,
  isAfter,
  addDays,
  subDays,
  startOfDay,
  endOfDay,
} from "date-fns";
import { format as formatTz } from "date-fns-tz";
import { LocalTimeZone } from "../constants/DateConstants";
import dayjs from "dayjs";
import { isEmpty, isNullOrEmpty } from "./CommonUtil";

export function formatDate(
  date?: Date,
  formatString: string = "yyyy-MM-dd"
): string {
  if (date === null || date === undefined) {
    return "N/A";
  } else {
    return format(date, formatString);
  }
}
export function unixToDate(value: any) {
  const date = dayjs.unix(value);
  const convertedDate = date.format("YYYY-MM-DD HH:mm:ss");
  return convertedDate;
}

export function toDateTimeLong(value?: Date) {
  if (isEmpty(value) === null) return null;
  const parseDateTime = dayjs(value);
  if (isEmpty(parseDateTime) === null) return null;
  return parseDateTime.format("YYYY-MM-DD HH:mm:ss");
}

export function toShortDate(value?: Date) {
  if (isEmpty(value) === null) return null;
  const parseDateTime = dayjs(value);
  if (isEmpty(parseDateTime) === null) return null;
  return parseDateTime.format("DD-MM-YYYY");
}

export function toReadableDate(value?: Date) {
  if (isEmpty(value) === null || isNullOrEmpty(value)) return null;
  const parseDateTime = dayjs(value);
  if (isEmpty(parseDateTime) === null) return null;
  return parseDateTime.format("DD-MMM-YYYY");
}

export function toReadableSafeEmptyDate(value?: Date): string {
  if (isEmpty(value) === null || isNullOrEmpty(value)) return "";
  const parseDateTime = dayjs(value);
  if (isEmpty(parseDateTime) === null) return "";
  return parseDateTime.format("DD-MMM-YYYY");
}

export function toReadableTime(value?: Date) {
  if (isEmpty(value) === null) return null;
  const parseDateTime = dayjs(value);
  if (isEmpty(parseDateTime) === null) return null;
  return parseDateTime.format("h:mm A");
}

export function formatTzDate(
  date: Date,
  formatString: string = "yyyy-MM-dd"
): string {
  const pattern = "d.M.yyyy HH:mm:ss.SSS 'GMT' XXX (z)";
  const output = formatTz(date, formatString, { timeZone: LocalTimeZone });
  return output;
}

export function parseISOString(isoString: string): Date {
  return parseISO(isoString);
}

export function isDateBefore(date1: Date, date2: Date): boolean {
  return isBefore(date1, date2);
}

export function isDateAfter(date1: Date, date2: Date): boolean {
  return isAfter(date1, date2);
}

export function addDaysToDate(date: Date, days: number): Date {
  return addDays(date, days);
}

export function subtractDaysFromDate(date: Date, days: number): Date {
  return subDays(date, days);
}

export function getStartOfDay(date: Date): Date {
  return startOfDay(date);
}

export function getEndOfDay(date: Date): Date {
  return endOfDay(date);
}

export function simpleReadableDate(
  date: Date,
  formatString: string = "dd-MM-yyyy"
): string {
  return format(date, formatString);
}
