import Swal from "sweetalert2";

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: "btn btn-primary",
    cancelButton: "btn btn-dark",
  },
  buttonsStyling: false,
});

export function PopupAlertSuccess(title: string, subTitle: string) {
  swalWithBootstrapButtons.fire({
    title: title,
    text: subTitle,
    icon: "success",
  });
}
export function PopupAlertError(title: string, subTitle: string) {
  swalWithBootstrapButtons.fire({
    title: title,
    text: subTitle,
    icon: "error",
  });
}

export function PopupAlertInfo(title: string, subTitle: string) {
  swalWithBootstrapButtons.fire({
    title: title,
    text: subTitle,
    icon: "info",
  });
}

export function PopupAlertSuccessNavigate(
  title: string,
  subTitle: string,
  navigate: any,
  navigateTo: string
) {
  swalWithBootstrapButtons
    .fire({
      title: title,
      text: subTitle,
      icon: "success",
      showCancelButton: false,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Okay",
      allowOutsideClick: false,
      allowEscapeKey: false,
    })
    .then((result) => {
      if (result.isConfirmed) {
        navigate(navigateTo);
      }
    });
}

export function PopupAlertCallback(
  title: string,
  subTitle: string,
  type: 'success' | 'error' | 'warning' | 'info',
  onConfirm: any,
) {
  swalWithBootstrapButtons
    .fire({
      title: title,
      text: subTitle,
      icon: type,
      showCancelButton: false,
      confirmButtonText: "Okay",
      allowOutsideClick: false,
      allowEscapeKey: false,
    })
    .then((result) => {
      if (result.isConfirmed) {
        onConfirm()
      }
    });
}
