export enum Role {
  User = 'USER',
  PlatformEditor = 'PLATFORM_EDITOR',
  Artist = 'ARTIST',
  Venue = 'VENUE',
  Agent = 'AGENT',
  ArtistManager = 'ARTIST_MANAGER',
  VenueManager = 'VENUE_MANAGER',
  AgentManager = 'AGENT_MANAGER',
  Basic = 'BASIC',
}
