import React from 'react'
import {ButtonGroup, Button} from 'react-bootstrap'
import {Controller} from '../../../infrastructure/deps/react-form'
import {Option} from '../../interfaces/Option'
import {isEmpty, isEmptyObject} from '../../../domain/utils/CommonUtil'

interface Props {
  options: Option[]
  mode: 'exclusive' | 'inclusive'
  value: string | string[]
  required?: boolean
  errors?: string
  onChange: (value: string) => void
  disabled?: boolean
}

interface InclusiveProps {
  options: Option[]
  mode: 'exclusive' | 'inclusive'
  value: string | string[]
  required?: boolean
  errors?: string
  onChange: (value: string[]) => void
  disabled?: boolean
}

interface ToggleButtonGroupProps {
  options: Option[]
  control: any
  name: string
  required?: boolean
  label: string
  errors?: string
  disabled?: boolean
  type: 'exclusive' | 'inclusive'
}

const ExclusiveImplementation: React.FC<Props> = ({options, mode, value, onChange, disabled, errors}) => {
  let columnDivider = `col-md-3`
  const handleToggle = (option: string) => {
    onChange(option)
  }
  const isSelected = (option: string) => {
    return Array.isArray(value) ? value.includes(option) : value === option
  }

  //determine css for the different number of items
  const numberOfItems = options.length
  if (numberOfItems === 2) {
    columnDivider = `col-md-6`
  } else if (numberOfItems === 3) {
    columnDivider = `col-md-4`
  }

  return (

    
    <ButtonGroup className={`${isEmpty(errors) ? '' : 'form-control is-invalid'}`}>
      <div className='row'>
        {options.map((option, index) => (
          <div className={columnDivider}>
            <Button
              key={index}
              style={{
                display: 'block',
                borderRadius: 20,
                alignItems: 'center',
                justifyItems: 'center',
                height: '80px',
                width: '100%',
              }}
              disabled={disabled}
              // variant={isSelected(option.value) ? 'primary' : 'secondary'}
              variant={isSelected(option.value) ? 'dark' : 'secondary'}
              size='lg'
              className='my-2 mx-2'
              onClick={() => handleToggle(option.value)}
            >
              {option.label}
            </Button>
          </div>
        ))}
      </div>
    </ButtonGroup>
  )
}

const InclusiveImplementation: React.FC<InclusiveProps> = ({
  options,
  mode,
  value,
  onChange,
  disabled,
  errors
}) => {
  let columnDivider = `col-md-3`
  const handleToggle = (option: string) => {
    const newValue = Array.isArray(value) ? value : []
    if (newValue.includes(option)) {
      onChange(newValue.filter((o) => o !== option))
    } else {
      onChange([...newValue, option])
    }
  }

  const isSelected = (option: string) => {
    return Array.isArray(value) ? value.includes(option) : value === option
  }
  //determine css for the different number of items
  const numberOfItems = options.length
  if (numberOfItems === 2) {
    columnDivider = `col-md-6`
  } else if (numberOfItems === 3) {
    columnDivider = `col-md-4`
  }
  return (
    <ButtonGroup className={`${isEmpty(errors) ? '' : 'form-control is-invalid'}`}>
      <div className='row'>
        {options.map((option, index) => (
          <div className={columnDivider}>
            <Button
              key={index}
              style={{
                display: 'block',
                borderRadius: 20,
                alignItems: 'center',
                justifyItems: 'center',
                height: '80px',
                width: '100%',
              }}
              disabled={disabled}
              className='my-2 mx-2'
              // variant={isSelected(option.value) ? 'primary' : 'secondary'}
              variant={isSelected(option.value) ? 'dark' : 'secondary'}
              onClick={() => handleToggle(option.value)}
              size='lg'
            >
              {option.label}
            </Button>
          </div>
        ))}
      </div>
    </ButtonGroup>
  )
}

const ToggleButtonGroup: React.FC<ToggleButtonGroupProps> = ({
  control,
  options,
  name,
  type,
  label,
  errors = '',
  required = false,
  disabled = false,
}) => {
  if (type === 'inclusive') {
    return (
      <div className='mt-4'>
        <label htmlFor={name}>{label}</label>
        <br />
        <Controller
          name={name}
          control={control}
          rules={{required: required}}
          render={({field: {onChange, value}}) => (
            <InclusiveImplementation
              options={options}
              disabled={disabled}
              mode='inclusive'
              value={value}
              errors={errors}
              onChange={(selectedValue: string[]) => onChange(selectedValue)}
            />
          )}
        />
        <div className='row'>
        {!isEmpty(errors) ? <span className='error text-danger'>{errors}</span> : ''}

        </div>
      </div>
    )
  }
  return (
    <div className='mt-4'>
      {' '}
      <label htmlFor={name}>{label}</label>
      <br />
      <Controller
        name={name}
        control={control}
        rules={{required: required}}
        render={({field: {onChange, value}}) => (
          <ExclusiveImplementation
            options={options}
            mode='exclusive'
            disabled={disabled}
            value={value}
            errors={errors}
            onChange={(selectedValue: string) => onChange(selectedValue)}
          />
        )}
      />
        <div className='row pr-5'>
        {!isEmpty(errors) ? <span className='error text-danger'>{errors}</span> : ''}

        </div>
    </div>
  )
}

export default ToggleButtonGroup
