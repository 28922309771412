import {useState} from 'react'
import {LocationFilter} from '../../../domain/entities/filters/LocationFilter'
import {
  useCityGetFilter,
  useLocationGetFilter,
} from '../../../domain/usecase/location/useLocationGetFilter'
import {
  getLength,
  getRequiredValidation,
  isEmpty,
  isNullOrEmpty,
  mapOptionsToCommaString,
  mapOptionsToNumberArray,
  mapToOptionsList,
} from '../../../domain/utils'
import {Control, Controller} from '../../../infrastructure/deps/react-form'
import {LocationApi} from '../../../infrastructure/remote/api/LocationApi'
import {LocationRepositoryImpl} from '../../../infrastructure/remote/repositories/location/LocationRepositoryImpl'
import {Option} from '../../interfaces/Option'
import AutoCompleteMultiple from '../AutoCompleteMultiple/AutoCompleteMultiple'
import RenderWhenGrid from '../RenderWhenGrid/RenderWhenGrid'

interface InputTextProps {
  errors?: any
  label: string
  provinces: any[] | undefined
  name: string
  className: string
  control: Control<any, any>
  required?: boolean
  disabled?: boolean
  showProvince?: boolean
  showCity?: boolean
  provinceLabel: string
  cityLabel: string
  provinceId: string
  cityId: string
  watch: any
}

const LocationMultiSelectCard = ({
  className,
  provinces,
  disabled,
  provinceLabel,
  cityLabel,
  provinceId,
  cityId,
  control,
  required,
  errors,
  label,
  name,
  watch,
}: InputTextProps) => {
  console.log('PROV', provinces)
  const [cityFilter, setCityFilter] = useState<LocationFilter>({
    type: '',
    countryId: 1,
  })

  // const provinceFilter =
  const cityLocationFilter = useCityGetFilter(new LocationRepositoryImpl(new LocationApi()))
  const selectedProvinceIds = watch(provinceId)
  const getCities = cityLocationFilter(mapOptionsToNumberArray(selectedProvinceIds))
  const provincesList = mapToOptionsList(provinces)
  console.log('PROVINCE SELECTED', selectedProvinceIds)
  return (
    <>
      <label htmlFor={name} className='mt-2'>
        {label}
      </label>
      <RenderWhenGrid visible={!isNullOrEmpty(provinceId)}>
        <AutoCompleteMultiple
          label={provinceLabel}
          control={control}
          options={provincesList}
          name={provinceId}
          className=''
          errors={errors?.[provinceId]?.message}
          getOptionLabel={function (option: Option): string {
            return option.label
          }}
          getOptionValue={function (option: Option): string {
            return option.value
          }}
        />
      </RenderWhenGrid>
      <RenderWhenGrid visible={!isNullOrEmpty(cityId)}>
        <AutoCompleteMultiple
          label={cityLabel}
          control={control}
          options={mapToOptionsList(getCities.data?.data)}
          name={cityId}
          errors={errors?.[cityId]?.message}
          getOptionLabel={function (option: Option): string {
            return option.label
          }}
          getOptionValue={function (option: Option): string {
            return option.value
          }}
        />
      </RenderWhenGrid>
    </>
  )
}

export default LocationMultiSelectCard
