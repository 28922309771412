import {FC} from 'react'
import {PerfomerBandFormProps} from '../../../../management/artist/props'
import {
  convertEnumToOptions,
  convertEnumToOptionsWithCleanLabel,
} from '../../../../../../domain/utils'
import {YesNoEnum, YesNoOnlyEnum} from '../../../../../../domain/enums/YesNoEnum'
import ToggleButtonGroup from '../../../../../components/ToggleButtonGroup/ToggleButtonGroup'
import {BandSizeEnum, printBandSize} from '../../../../../../domain/enums/BandSizeEnum'
import {YesNoBothEnum} from '../../../../../../domain/enums/YesNoBothEnum'
import {
  PerformerBandEnum,
  printPerformerBand,
} from '../../../../../../domain/enums/PerformerBandEnum'
import {AutoCompleteMultiple, InputText, InputTextArea, Title} from '../../../../../components'
import InputUrl from '../../../../../components/InputText copy/InputUrl'
import PayRateInfoCard from '../../../partials/PayRateInfoCard'
import CommunityAdditionalInfoCard from '../../../partials/CommunityAdditionalInfoCard'
import {Option} from '../../../../../interfaces/Option'
import {GenresEnum, printGenres} from '../../../../../../domain/enums/GenresEnum'
import {OriginalContentCoversEnum, getOriginalCoversEnumOptions} from '../../../../../../domain/enums/OriginalContentCoversEnum'
import LocationMultiSelectCard from '../../../../../components/LocationMultiSelectCard/LocationMultiSelectCard'

const PerfomerBandForm: FC<PerfomerBandFormProps> = ({control, errors, watch, provinces}) => {
  const yesNoOptions = convertEnumToOptions(YesNoEnum)
  const yesNoOnlyOptions = convertEnumToOptions(YesNoOnlyEnum)
  const yesNoBothOptions = convertEnumToOptions(YesNoBothEnum)
  const artistBandSizeOptions = convertEnumToOptionsWithCleanLabel(BandSizeEnum, printBandSize)
  const genresOptions = convertEnumToOptionsWithCleanLabel(GenresEnum, printGenres)
  const artistPerformerBandOptions = convertEnumToOptionsWithCleanLabel(
    PerformerBandEnum,
    printPerformerBand
  )

  return (
    <>
      <div className='col-md-12 mt-5'>
        <Title type={'xxs'}>Artist Information</Title>
      </div>
      {/* TODO:: add this field to api */}
      <div className='col-md-12 mt-5'>
        <ToggleButtonGroup
          name={'subtype'}
          label={'Type'}
          type='inclusive'
          options={artistPerformerBandOptions}
          errors={errors?.subtype?.message}
          control={control}
        />
      </div>

      {/* <div className='col-md-6'>
        <InputText
          control={control}
          type='text'Y
          name={'name'}
          required={false}
          disabled={false}
          label={'Performer / Band Title'}
          errors={errors.name?.message}
          className='form-control mt-5'
        />
      </div> */}

      {/* <div className='col-md-6'>
        <InputTextArea
          control={control}
          rows={4}
          name={'bandGenres'}
          required={false}
          disabled={false}
          label={'Genre(s) (List all that apply, separated by a comma)'}
          errors={errors.name?.message}
          className='form-control mt-5'
        />
      </div> */}
      <div className='col-md-6 mt-5'>
        <AutoCompleteMultiple
          label={'Genre(s) (List all that apply, separated by a comma)'}
          control={control}
          options={genresOptions}
          name={'genres'}
          errors={errors.roles?.message}
          getOptionLabel={function (option: Option): string {
            return option.label
          }}
          getOptionValue={function (option: Option): string {
            return option.value
          }}
        />
      </div>
      <div className='col-md-6 mt-5'>
         <ToggleButtonGroup
          name={'plays_originals_covers_both'}
          label={'Originals or Covers?'}
          type='exclusive'
          options={getOriginalCoversEnumOptions()}
          control={control}
        /> 
      </div>

      {/* <div className='col-md-12 mt-5'>
        <ToggleButtonGroup
          name={'artistBandSize'}
          label={'Size'}
          type='exclusive'
          options={artistBandSizeOptions}
          control={control}
        />
      </div> */}

      {/* TODO:: add this field to api  */}

      {/* <div className='col-md-6 mt-5'>
        <InputText
          control={control}
          errors={errors.yearsOfExperienceAsPerformerBand?.message}
          required={false}
          disabled={false}
          name={'yearsOfExperienceAsPerformerBand'}
          label={'Years of Experience as this Performer / Band'}
          className='form-control'
          type={'number'}
        />
      </div> */}

      {/* TODO:: add this field to api  */}
      {/* <div className='col-md-6 mt-5'>
        <InputUrl
          control={control}
          errors={errors.stagePlot?.message}
          required={false}
          disabled={false}
          name={'stagePlot'}
          label={'Stage Plot (if applicable)'}
          className='form-control'
        />
      </div> */}

      {/* TODO:: add this field to api  */}

      {/* <div className='col-md-6 mt-5'>
        <ToggleButtonGroup
          name={'artistPlayLiveElectronic'}
          label={'Do you play live or electronic?'}
          type='exclusive'
          options={yesNoBothOptions}
          control={control}
        />
      </div> */}
      {/* 
      <div className='col-md-6 mt-5'>
        <ToggleButtonGroup
          name={'explicitContent'}
          label={'Is your content explicit?'}
          type='exclusive'
          options={yesNoOnlyOptions}
          control={control}
        />
      </div> */}

      <CommunityAdditionalInfoCard control={control} errors={errors} />
      <div className='col-md-12 mt-5'>
        <Title type={'xxs'}>Offer Information</Title>
      </div>
      <PayRateInfoCard
        control={control}
        errors={errors}
        rateQuestion='Minimum rate per SET or SERVICE.'
      />
    </>
  )
}

export default PerfomerBandForm
