import {FC, useState} from 'react'
import {ArtistEnum, printArtist} from '../../../../domain/enums/ArtistEnum'
import {PaymentRateEnum} from '../../../../domain/enums/PaymentRateEnum'
import {YesNoOnlyEnum} from '../../../../domain/enums/YesNoEnum'
import {
  convertEnumToOptions,
  convertEnumToOptionsWithCleanLabel,
  getRequiredValidation,
  isEmpty,
  isEmptyObject,
} from '../../../../domain/utils'
import {H5, InputText, P, RenderWhenGrid} from '../../../components'
import {ArtistFormProps} from '../../management/artist/props'
import EducationClinicianForm from '../../shared/forms/onboarding/artist/EducationClinicianForm'
import PerfomerBandForm from '../../shared/forms/onboarding/artist/PerfomerBandForm'
import ProducerEngineerForm from '../../shared/forms/onboarding/artist/ProducerEngineerForm'
import SessionInstrumentalistForm from '../../shared/forms/onboarding/artist/SessionInstrumentalistForm'
import ContactCard from '../../shared/partials/ContactCard'
import ToggleButtonGroup from '../../../components/ToggleButtonGroup/ToggleButtonGroup'
import PopUp from '../../../components/PopUp/PopUp'
import LocationMultiSelectCard from '../../../components/LocationMultiSelectCard/LocationMultiSelectCard'
import { getMusicVenueTypeOptions } from '../../../../domain/enums/MusicVenueTypeEnum'

const ArtistOnboardingForm: FC<ArtistFormProps> = ({control, errors, watch, provinces}) => {
  const yesNoOnlyOptions = convertEnumToOptions(YesNoOnlyEnum)
  const paymentTypeOptions = convertEnumToOptions(PaymentRateEnum)
  const artistType = watch('artistType')
  const artistPriceType = watch('artistPriceType')
  const artistHasLabel = watch('artistHasLabel')
  const artistTypeOptions = convertEnumToOptionsWithCleanLabel(ArtistEnum, printArtist)

  return (
    <>
      <div className='row'>
        <div className='col-md-6 mt-5'>
          {/* TODO:: capture */}
          <LocationMultiSelectCard
            provinceLabel={'Where are you wanting to perform? Select all that apply'}
            cityLabel='Specific Cities'
            provinceId={'location_id'}
            cityId={'pref_city_id'}
            control={control}
            // required={true}
            errors={errors}
            label={'Location'}
            name={'preference-loc-card'}
            provinces={provinces}
            className={''}
            watch={watch}
          />
        </div>

        <div className='col-md-12'>
          <div className='col-md-6'>
            <InputText
              control={control}
              type='text'
              name={'name'}
              // required={true}
              disabled={false}
              label={'What is your preferred name?'}
              errors={errors?.name?.message}
              className='form-control mt-5'
            />
          </div>
        </div>

        <div className='col-md-12 mt-5'>
          <ToggleButtonGroup
            name={'artistType'}
            label={'What type of Artist?'}
            type='exclusive'
            // required={true}
            errors={errors?.artistType?.message}
            options={artistTypeOptions}
            control={control}
          />
        </div>

        {/* TODO:: add to api  */}
        <div className='col-md-12 mt-5'>
          <ToggleButtonGroup
            name={'performance_preferred_venues'}
            label={'What type of venues DO YOU NOT perform at?'}
            type='inclusive'
            options={getMusicVenueTypeOptions()}
            control={control}
          />
        </div>

        <div className='col-md-6 mt-5'>
          <ToggleButtonGroup
            name={'artistHasLabel'}
            label={'Do you have a label?'}
            type='exclusive'
            options={yesNoOnlyOptions}
            control={control}
          />
        </div>
        {/* <RenderWhenGrid visible={artistHasLabel === YesNoOnlyEnum.Yes}>
          <div className='col-md-6'>
            <InputText
              control={control}
              type='text'
              // required={artistHasLabel === YesNoOnlyEnum.Yes}
              disabled={false}
              name={'artistLabelName'}
              label={'What is the name of your label?'}
              errors={getRequiredValidation(errors.artistLabelName)}
              className='form-control mt-5'
            />
          </div>
        </RenderWhenGrid> */}
        {/* DONT NOT REMOVE */}
        {/* <div className='col-md-12 mt-5'>
          <ToggleButtonGroup
            name={'artistPriceType'}
            label={'Pricing'}
            type='inclusive'
            options={paymentTypeOptions}
            control={control}
          />
        </div>
        <RenderWhenGrid visible={safeItemSelectionInCollectionBoolean(PaymentRateEnum.Fixed,artistPriceType)}>
          <div className='col-md-6'>
            <InputText
              control={control}
              type='number'
              required={false}
              disabled={false}
              name={'artistMinimumPrice'}
              label={'Fixed Price'}
              errors={errors.artistMinimumPrice?.message}
              className='form-control mt-5'
            />
          </div>
        </RenderWhenGrid>
        <RenderWhenGrid visible={safeItemSelectionInCollectionBoolean(PaymentRateEnum.Hourly,artistPriceType)}>
          <div className='col-md-6'>
            <InputText
              control={control}
              errors={errors.artistHourlyRate?.message}
              type='number'
              required={false}
              disabled={false}
              name={'artistHourlyRate'}
              label={'Hourly Rate'}
              className='form-control mt-5'
            />
          </div>
        </RenderWhenGrid> */}
        {/* Performer Band */}
        <RenderWhenGrid visible={artistType === ArtistEnum.PerformerBand}>
          <PerfomerBandForm control={control} errors={errors} watch={watch} provinces={provinces} />
        </RenderWhenGrid>
        {/* Session Artist */}
        <RenderWhenGrid visible={artistType === ArtistEnum.SessionArtistInstrumentals}>
          <SessionInstrumentalistForm
            control={control}
            errors={errors}
            watch={watch}
            provinces={provinces}
          />
        </RenderWhenGrid>
        {/* Record Producer/Studio Engineer */}
        <RenderWhenGrid visible={artistType === ArtistEnum.RecordProducerStudioEngineer}>
          <ProducerEngineerForm
            control={control}
            errors={errors}
            watch={watch}
            provinces={provinces}
          />
        </RenderWhenGrid>
        {/* Teacher */}
        <RenderWhenGrid visible={artistType === ArtistEnum.TeacherClinician}>
          <EducationClinicianForm
            control={control}
            errors={errors}
            watch={watch}
            provinces={provinces}
          />
        </RenderWhenGrid>

        {/* Location */}

        {/* How Do We Reach You */}
        <ContactCard
          id={''}
          label='Contact Information'
          control={control}
          errors={errors}
          website={false}
          phone={true}
          emailAddress={true}
          emailAddressId='contact'
          spotify={false}
          facebook={false}
          instagram={false}
          youtube={false}
          tiktok={false}
          shareProfile={false}
          showSocials={false}
          representingArtist={true}
          representingLabelOrAgency={true}
          //firstName={true}
          name={true}
        />
      </div>
    </>
  )
}

export default ArtistOnboardingForm
